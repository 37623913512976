import React, { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Divider, TextField } from '@mui/material';
// import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import "../css/customers.css"
import { useNavigate } from 'react-router-dom';
import CustomerMod from './customermod';
import * as XLSX from 'xlsx';
// import { DataGridPro } from '@mui/x-data-grid-pro';

// import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import Pagination from '../comp/pagination';
import CustTable from '../comp/custtable/custtable';
import CustAdd from './custadd';
import RefreshIcon from '@mui/icons-material/Refresh';
// import { report } from 'process';

const Customers = ({ token }) => {
    const [items, setItems] = useState([]);
    const [modal, setmodal] = useState(false);
    const [custrow, setcustrow] = useState([]);
    const [loading, setLoading] = useState(true);
    const [nativeScroll, setNativeScroll] = useState(false);
    const [autoHide, setAutoHide] = useState(false);
    const [alwaysShowTrack, setAlwaysShowTrack] = useState(false);
    const [[start, end], setSend] = useState([0, 20]);
    const [allitemcount, setAllCount] = useState(0);
    const [page, setPage] = useState([]);
    const sfield = useRef();
    const [sstr, setSearch] = useState("");
    const [newmod, setNewMod] = useState(false);

    const navigate = useNavigate();
    const custsearch = useRef();
    const dataref = useRef();

    console.log("customer: " + token);

    const columns = [
        { field: 'id', headerName: 'Код', editable: true, flex: 1 },
        { field: 'cust_name', headerName: 'Нэр', editable: true, flex: 2 },
        { field: 'cust_reg_num', headerName: 'Регистр', editable: true, flex: 1 },
        { field: 'cust_phone', headerName: 'Утасны дугаар', editable: true, flex: 1 },
        { field: 'cust_address', headerName: 'Хаяг', editable: true, flex: 1 },
        { field: 'cust_loan_amt', headerName: 'Зээлийн дүн', editable: true, flex: 1 },
        { field: 'cust_location', headerName: 'Байршил', editable: true, flex: 1 },
        { field: 'cust_cre_time', headerName: 'Үүссэн огноо', editable: true, flex: 1 },
        { field: 'cust_bonus_prcnt', headerName: 'Урамуулалын хувь', editable: true, flex: 1 },
    ];

    const downloadExcel = () => {
        var url = 'https://wms-cloud.com/api/Customers?_start=0&_end=10000';
        if (sstr !== "") {
            url = url + '&cust_name=' + sstr;
        }
        const request = new Request(url, {
            method: 'GET',

            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        console.log(JSON.stringify(request)
        );
        fetch(request).then(
            (response) => {
                console.log(" stat: " + response.status);
                if (response.status == 200) {
                    console.log("header:" + response.headers.get('X-Total-Count')
                    );
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    localStorage.removeItem('tok');
                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }

            }
        ).then((resp) => {
            setLoading(false);
            const workSheet = XLSX.utils.json_to_sheet(resp);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "students");
            //Buffer
            let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
            //Download
            XLSX.writeFile(workBook, "Customers" + ".xlsx");
        });
    }


    useEffect(() => {
        console.log("calling");
        // setSend([0, 20]);
        var url = 'https://wms-cloud.com/api/Customers?_start=' + start + '&_end=' + end;
        if (sstr !== "") {
            url = url + '&cust_name=' + sstr;
        }
        const request = new Request(url, {
            method: 'GET',

            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        // console.log("token" + token);
        console.log(JSON.stringify(request)
        );
        fetch(request).then(
            (response) => {
                console.log(" stat: " + response.status);
                if (response.status == 200) {
                    console.log("header:" + response.headers.get('X-Total-Count')
                    );
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }

            }
        ).then((resp) => {
            console.log(resp);
            setItems(resp);

            setLoading(false);
        });

    }, [start, end, sstr]);



    const handleRowClick = (e) => {
        // console.log(e);
        setcustrow(e);
        setmodal(true);
    }


    function rowKeyGetter(row) {
        return row.id;
    }

    const handlePage = (e) => {
        console.log("from cust: " + e);
        setSend([e * 20, (e * 20 + 20)]);
    }

    const custSearchBar = (e) => {
        if (e.keyCode == 13) {
            setSearch(custsearch.current.value);
            setLoading(true);
        }
    }

    const custRefresh = () => {
        window.location.reload(false);
    }



    console.log("customer: " + token);

    return <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="parenthead">
            <div>
                <h3>Харилцагч</h3>
            </div>
            <div>
                <Button style={{ marginLeft: "3px", height: "40px" }} variant="outlined" onClick={() => { setNewMod(true) }} >Харилцагч үүсгэх</Button>
                <TextField style={{ marginLeft: "5px" }} inputRef={custsearch} size="small" label="Нэрээр хайх" onKeyDown={custSearchBar} />
                <Button style={{ marginLeft: "3px", height: "40px" }} variant="outlined" onClick={() => { downloadExcel() }} >Excel татах</Button>
                <Button variant="outlined" style={{ marginLeft: "3px", height: "40px" }} onClick={() => {
                    // setSearch("");
                    // setSend([0, 20]);
                    // custsearch.current.value = "";
                    custRefresh();
                }}> <RefreshIcon /> </Button>
            </div>
        </div>
        <div className="tablecont" >
            <CustTable
                token={token}
                name={"Customers"}
                columns={columns}
                items={items}
                allcont={allitemcount}
                handlePage={handlePage}
                handleSearch={setSearch}
                rowClick={handleRowClick}
                createshow={true}
                newmod={newmod}
                loading={loading}
                handleCreate={setNewMod}
                cellname={"cust_name"} />
        </div>
        {modal ?
            <CustomerMod
                handleClose={setmodal}
                token={token}
                open={modal}
                custrow={custrow}
                handleModal={setmodal}
                newmod={true}
                handleCreate={setNewMod}
                handleRefresh={custRefresh}
            /> : <></>
        }
        {newmod ? <CustAdd
            token={token}
            open={newmod}
            handleClose={setNewMod}
            handleRefresh={custRefresh}
        /> : <></>}
    </div>
}

export default Customers;   