import { Button, Divider, CircularProgress, Table, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useRef, useEffect, useState } from 'react';
import '../css/prodmodal.css'
import ProductBarcodeAuto from "../comp/prodbarcodeauto";
import ProductNameAuto from "../comp/prodnameauto";
import CustomerNameAuto from '../comp/custnameauto';

const TranReverseAdd = ({ handleCloserev,  open, custrow, handleModal, handleRefreshrev }) => {

    const [prodload, setProdload] = useState(false);
    const [selected, setSelected] = useState({ prod_code: "Баркод", prod_name: "Бүтээгдэхүүний Нэр" });
    const [selectedCust, setSelectedCust] = useState({ cust_id: "Customer", cust_name: "Customer" });
    const [options, setOptions] = useState([]);
    const [optionsCust, setOptionsCust] = useState([]);
    const [inputVal] = useState({});
    const txn_prod_too = useRef();
    const txn_amount = useRef();
    const txn_ord_id = useRef();
    const txn_cust_id = useRef();
    const tok = localStorage.getItem('tok');


    const handleEscape = (e) => {
        if (e.keyCode == 27) {
            handleCloserev(false);
        }

    }

    console.log("transaction selected:" + JSON.stringify(selected));

    useEffect(() => {
        
        window.addEventListener('keyup', handleEscape);
        return () => {
            window.removeEventListener('keyup', handleEscape);
        }
    }, [])


    const handleClick = () => {
        handleClick(false);
    }

    const handleSubmit = () => {
        setProdload(true);
        var req = {
            'txn_prod_code': selected.prod_code,
            'txn_prod_too': parseInt(txn_prod_too.current.value),
            'txn_amount': parseFloat(txn_amount.current.value),
            'txn_ord_id': txn_ord_id.current.value,
            'txn_cust_id' : selectedCust.cust_id
        }

        console.log("req "+JSON.stringify(req));

        const request = new Request('https://wms-cloud.com/api/TranReverse', {
            method: 'POST',
            body: JSON.stringify(req),
            headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${tok}` })
        });

        fetch(request).then((response) => {
            if (response.status === 200) {
                console.log("aldaa garaagu");
                alert("Амжилттай");
                setProdload(false);
                handleCloserev(false);
                handleRefreshrev();

            } else {
                console.log("aldaa garsan" + response.status);
                alert("Ажилтгүй дахин оролдоно уу. " + JSON.stringify(response));
                setProdload(false);
            }
        });


    }

    const getdataforCustname = (e) => {

        var tok = localStorage.getItem("tok");
        var url = "";
        if (e == null) {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + selected.cust_name;

        } else {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + e.target.value;
        }
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    localStorage.removeItem('tok');
                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            var temp = [];
            resp.map((e) => {
                temp.push({ "cust_id": e.cust_id, "cust_name": e.cust_name });
            });
            setOptionsCust(temp);
        });
    }

    const getdataforname = (e) => {
        var tok = localStorage.getItem("tok");
        var url = "";
        if (e == null) {
            url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&prod_name=' + selected.prod_name;

        } else {
            url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&prod_name=' + e.target.value;
        }
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });

        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    localStorage.removeItem('tok');
                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("from here 2");
            var temp = [];
            resp.map((e) => {
                temp.push({ "prod_code": e.prod_code, "prod_name": e.prod_name, "prod_price": e.prod_price, "prod_uldegdel": e.prod_uldegdel });
            });
            setOptions(temp);
        });
    }

    const getdataforbar = (e) => {
        console.log("changevallen" + e);
        var tok = localStorage.getItem("tok");

        var url = "";
        if (e == null) {
            console.log("e null");
            url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&q=' + selected.prod_code;

        } else {
            url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&q=' + e.target.value;
        }
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });

        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    localStorage.removeItem('tok');
                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("from here 2");
            var temp = [];
            resp.map((e) => {
                temp.push({ "prod_code": e.prod_code, "prod_name": e.prod_name, "prod_price": e.prod_price, "prod_uldegdel": e.prod_uldegdel });
            });
            setOptions(temp);
        });
        // }
    }


    return <div className="custmodal" >
        <div className="mcont">
            <div className="mheader">
                Харилцагч буцаалт шивэх
            <button onClick={() => handleCloserev()}>x</button>
            </div>
            <Divider />
            <div className="mccont">

                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <ProductBarcodeAuto selected={selected} label="Баркод" setProd={setSelected} options={options} getdataforbar={getdataforbar} inputVal={inputVal} />
                        <div>{selected.prod_price!=undefined?" Баркод: "+selected.prod_code+" Нэр: "+selected.prod_name+" "+"Үнэ: "+selected.prod_price+" "+"Үлдэгдэл: "+selected.prod_uldegdel:""}</div>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                    <ProductNameAuto selected={selected} label="Барааны нэр" setProd={setSelected} options={options} getdataforbar={getdataforname} inputVal={inputVal}/>
                    <div>{selected.prod_price!=undefined?" Баркод: "+selected.prod_code+" Нэр: "+selected.prod_name+" "+"Үнэ: "+selected.prod_price+" "+"Үлдэгдэл: "+selected.prod_uldegdel:""}</div>
                    </div>
                </div>

                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                    <CustomerNameAuto selected={selectedCust} setProd={setSelectedCust} options={optionsCust} getdataforbar={getdataforCustname}/>
                    </div>
                </div>
                
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={txn_prod_too} label="Тоо ширхэг" type="number" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={txn_amount} label="Үнэ" type="number" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={txn_ord_id} label="Падааны дугаар" type="number" fullWidth ></TextField>
                    </div>
                </div>

            </div>
            <div className="mfooter">
                <Button onClick={handleSubmit}>Хадгалах</Button>
                <Button onClick={() => handleCloserev()}>Буцах</Button>
            </div>
            {prodload ?
                <div className="prodload">
                    <CircularProgress />
                </div> : ""}
        </div>
    </div >
}

export default TranReverseAdd;
