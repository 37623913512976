
// import React, { useState } from 'react';
import CustTable from '../comp/custtable/custtable';
import React, { useState, useEffect, useRef } from 'react';
import { Button, CircularProgress, TextField } from '@mui/material';
import "../css/transactions.css";
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TransactionAdd from './transactionAdd';
import TranReverseAdd from './tranreverseadd';
import TranReturnAdd from './tranreturnadd';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import Companydown from '../comp/companydown';
import CustomerNameAuto from '../comp/custnameauto';
import RefreshIcon from '@mui/icons-material/Refresh';
import CompFilter from '../comp/compfilter';
import * as XLSX from 'xlsx';
// import { getCurrentDate } from './utils'



const Transactions = ({ token }) => {
    const [[start, end], setSEnd] = useState([0, 20]);
    const [items, setItems] = useState([]);
    const [allcont, setAllCount] = useState(0);
    const [exportitems, setexportItems] = useState([]);
    const [loading, setLoad] = useState(false);
    // const [datevalue, setDateValue] = useState("");
    const [startdate, setStartDate] = React.useState(dayjs());
    const [actstart, setActStart] = React.useState();
    const [enddate, setEndDate] = React.useState(dayjs());
    const [actend, setActEnd] = React.useState();
    const [barcode, setBarcode] = useState();
    const [subrow, setSubrow] = useState(false);
    const [Txntype, setTxntype] = useState();
    const [comp, setComp] = useState("");
    const [searchcust, setSCust] = useState();
    const [addmodal, setAddModal] = useState(false);
    const [tranrev, settranrev] = useState(false);
    const [tranreturn, settranreturn] = useState(false);
    const bsearch = useRef();
    const txntypeSearch = useRef();
    const [selected, setSelected] = useState({ cust_id: "Customer", cust_name: "Customer" });
    const [options, setOptions] = useState([]);
    const [selectedData, setselectedData] = useState([]);

    console.log("asd: " + searchcust);

    useEffect(() => {
        setLoad(true);

        var url = 'https://wms-cloud.com/api/Transactions?_start=' + start + '&_end=' + end;


        if (actstart != undefined) {
            // console(format(startdate, 'yyyy-MM-DD'));
            url = url + '&txn_from_date=' + actstart;
            console.log(url);
        }

        if (actend != undefined) {
            url = url + '&txn_to_date=' + actend;
            console.log(url);
        }

        if (barcode != undefined) {
            url = url + '&barcode=' + barcode;
        }

        if(Txntype != undefined) {
            url = url + '&txn_type=' +Txntype;
        }

        if (comp != "") {
            url = url + "&txn_prod_comp_id=" + comp;
        }

        if (selected.cust_id != "Customer") {
            url = url + '&txn_cust=' + selected.cust_id;
        }

        console.log(url);

        const request = new Request(url, {
            method: 'GET',
            // headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` })
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    console.log(response.headers.get('X-Total-Count'));
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log(JSON.stringify(resp));
            setItems(resp);
            setLoad(false);

        });
    }, [start, end, startdate, enddate, barcode, selected, Txntype, comp])

    const handlePage = (e) => {
        setLoad(true);
        console.log(e);
        setSEnd([e * 20, (e * 20) + 20]);

    }

    const custRefresh = () => {
        window.location.reload(false);
    }

    const custDataRefreshrev = () => {
        setLoad(true);

        var url = 'https://wms-cloud.com/api/Transactions?_start=' + start + '&_end=' + end;


        if (actstart != undefined) {
            // console(format(startdate, 'yyyy-MM-DD'));
            url = url + '&txn_from_date=' + actstart;
            console.log(url);
        }

        if (actend != undefined) {
            url = url + '&txn_to_date=' + actend;
            console.log(url);
        }

        if (barcode != undefined) {
            url = url + '&barcode=' + barcode;
        }

        if(Txntype != undefined) {
            url = url + '&txn_type=' +Txntype;
        }

        if (comp != "") {
            url = url + "&txn_prod_comp_id=" + comp;
        }

        if (selected.cust_id != "Customer") {
            url = url + '&txn_cust=' + selected.cust_id;
        }

        console.log(url);

        const request = new Request(url, {
            method: 'GET',
            // headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` })
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    console.log(response.headers.get('X-Total-Count'));
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log(JSON.stringify(resp));
            setItems(resp);
            setLoad(false);

        });
    }

    const custDataRefresh = () => {
        setLoad(true);

        var url = 'https://wms-cloud.com/api/Transactions?_start=' + start + '&_end=' + end;


        if (actstart != undefined) {
            // console(format(startdate, 'yyyy-MM-DD'));
            url = url + '&txn_from_date=' + actstart;
            console.log(url);
        }

        if (actend != undefined) {
            url = url + '&txn_to_date=' + actend;
            console.log(url);
        }

        if (barcode != undefined) {
            url = url + '&barcode=' + barcode;
        }

        if(Txntype != undefined) {
            url = url + '&txn_type=' +Txntype;
        }

        if (comp != "") {
            url = url + "&txn_prod_comp_id=" + comp;
        }

        if (selected.cust_id != "Customer") {
            url = url + '&txn_cust=' + selected.cust_id;
        }

        console.log(url);

        const request = new Request(url, {
            method: 'GET',
            // headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` })
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    console.log(response.headers.get('X-Total-Count'));
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log(JSON.stringify(resp));
            setItems(resp);
            setLoad(false);

        });
    }

    const handleStartChange = (e) => {
        setLoad(true);
        var t = new Date(e);
        var d = format(t, 'yyyy-MM-dd');
        console.log("e: " + e + " : " + t + " : " + d);
        setStartDate(e);
        setActStart(d);
    }

    const handleEndChange = (e) => {
        setLoad(true);
        var t = new Date(e);
        var d = format(t, 'yyyy-MM-dd');
        setEndDate(e);
        setActEnd(d);
    }

    const handleBarCodeSearch = (e) => {
        // console.log("test: " + JSON.stringify(e));

        if (e.keyCode == 13) {
            setLoad(true);
            console.log("submitted");
            console.log(bsearch.current.value);
            setBarcode(bsearch.current.value);
        }

    }

    const handleTxntypeSearch = (e) => {
        // console.log("test: " + JSON.stringify(e));

        if (e.keyCode == 13) {
            setLoad(true);
            console.log("submitted");
            console.log(txntypeSearch.current.value);
            setTxntype(txntypeSearch.current.value);
        }

    }

    const getdataforname = (e) => {
        // console.log("changevallen" + e.target.value);
        // if (e.target.value.length > 0) {
        var tok = localStorage.getItem("tok");
        var url = "";
        if (e == null) {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + selected.cust_name;

        } else {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + e.target.value;
        }
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&prod_code=' + e.target.value;
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=5000';
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });

        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("from here 2");
            // console.log(JSON.stringify(resp));
            var temp = [];
            resp.map((e) => {
                temp.push({ "cust_id": e.cust_id, "cust_name": e.cust_name });
            });
            // console.log("temp:" + JSON.stringify(temp));
            setOptions(temp);
        });
        // }
    }

    const DeleteTranBulk = () => {
        setLoad(true);
        var btoken = localStorage.getItem('tok');
        for(var i=0;i<selectedData.length;i++)
        {
            console.log("delete tranid for: "+ selectedData[i].id);

            const request = new Request('https://wms-cloud.com/api/Transactions/'+selectedData[i].id, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${btoken}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
                })
            });

            console.log(
                JSON.stringify(request)
            );

            fetch(request).then(
                (response) => {
                    console.log("response: " + JSON.stringify(response));
                    if (response.status == 200) {
                        return response.json();
                    } else if (response.status == 500) {
                        alert("Алдаа гарлаа дахин оролдоно уу.");
                    } else {
                        localStorage.removeItem('tok');
                        //window.location.reload(false);
                        alert("Алдаа гарлаа.")
                    }
                }
            ).then((resp) => {
                console.log("resp: " + JSON.stringify(resp));
                alert(resp.status);
            });
        }
        setLoad(false);
        //window.location.reload(false);
    }

    const downloadExcel = () => {
        var url = 'https://wms-cloud.com/api/Transactions?_start=0&_end=10000'
        //var url = 'http://localhost:8080/Transactions?_start=0&_end=10000'
        if (actstart != undefined) {
            // console(format(startdate, 'yyyy-MM-DD'));
            url = url + '&txn_from_date=' + actstart;
            console.log(url);
        }

        if (actend != undefined) {
            url = url + '&txn_to_date=' + actend;
            console.log(url);
        }

        if (barcode != undefined) {
            url = url + '&barcode=' + barcode;
        }
        if(Txntype != undefined) {
            url = url + '&txn_type=' +Txntype;
        }

        if (comp != "") {
            url = url + "&txn_prod_comp_id=" + comp;
        }

        if (selected.cust_id != "Customer") {
            url = url + '&txn_cust=' + selected.cust_id;
        }
        const request = new Request(url
            , {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    
                    'Access-Control-Allow-Headers': 'X-Total-Count'
                })
            });
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    console.log('status 200');
                    // console.log(response.headers.get('X-Total-Count'));
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.state == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            // console.log(JSON.stringify(resp));
            // changeLoad();
            setLoad(false);
            setexportItems(resp);

            const workSheet = XLSX.utils.json_to_sheet(resp);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "students");
            //Buffer
            let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
            //Download
            XLSX.writeFile(workBook, "Transactions" + ".xlsx");

        });

    };


    const columns = [
        { field: 'txn_type', headerName: 'Төрөл', },
        { field: 'txn_prod_code', headerName: 'Баркод', width: 150 },
        { field: 'txn_prod_name', headerName: 'Бүтээгдхүүний нэр', width: 300},
        { type: 'number', field: 'txn_amount', headerName: 'Үнэ', },
        { type: 'number', field: 'txn_prod_too', headerName: 'Too ширхэг', },
        { type: 'number', field: 'txn_sum_amount', headerName: 'Нийт дүн', },
        { field: 'txn_emp_id', headerName: 'Борлуулагч', },
        { field: 'txn_cust_id', headerName: 'Харилцагч', },
        { field: 'txn_cust_name', headerName: 'Харилцагч', width: 150  },
        { field: 'txn_date', headerName: 'Огноо', },
        { field: 'txn_ord_id', headerName: 'Зах/Дугаар', },
        { field: 'txn_com_name', headerName: 'Компани нэр', },
        { field: 'txn_prod_comp_id', headerName: 'Компани код', },
    ];


    return <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="parenthead" >
            <div>
                <h3>Гүйлгээ</h3>
            </div>
            {subrow ? 
            
            <div style={{ height: "45px", border: "1px solid black", marginBottom: "5px", border: "1px solid rgba(224, 224, 224, 1)", display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "5px", borderRadius: "5px" }}>
                <Button variant="outlined" color="error" onClick={DeleteTranBulk} >
                    Орлого устгах
            </Button>
            </div> 
            :
            <div style={{ display: "flex", }}>
            <Button style={{ marginRight: "3px", height: "40px" }} variant="outlined" onClick={setAddModal} >Орлого авах</Button>
            {localStorage.getItem('role').includes('115') ? <Button style={{ marginRight: "3px", height: "40px" }} variant="outlined" onClick={settranrev} >Харилцагч буцаалт</Button> : ""}
            {localStorage.getItem('role').includes('115') ? <Button style={{ marginRight: "3px", height: "40px" }} variant="outlined" onClick={settranreturn} >Нийлүүлэгч буцаалт</Button> : ""}
            
            
                <div style={{ width: "150px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Эхлэх огноо"
                                inputFormat="YYYY-MM-DD"
                                value={startdate}
                                onChange={handleStartChange}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </div>
                <div style={{ width: "10px" }}></div>
                <div style={{ width: "150px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Дуусах огноо"
                                inputFormat="YYYY-MM-DD"
                                value={enddate}
                                onChange={handleEndChange}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </div>
                {/* <div style={{ width: "150px", marginLeft: "3px", marginRight: "3px" }}>
                    <Companydown token={token} />
                </div> */}
                <div style={{ width: "190px", marginLeft: "3px", marginRight: "3px" }}>
                    {/* <Custdown token={token} setdata={setSCust} /> */}
                    <CustomerNameAuto selected={selected} setProd={setSelected} options={options} getdataforbar={getdataforname}/>
                </div>
                <div style={{ width: "150px" }}>
                    <TextField style={{ marginLeft: "5px" }} inputRef={bsearch} size="small" label="Баркод" onKeyDown={handleBarCodeSearch} />
                </div>
                <div style={{ width: "150px" }}>
                    <TextField style={{ marginLeft: "5px" }} inputRef={txntypeSearch} size="small" label="Орлого зарлага" onKeyDown={handleTxntypeSearch} />
                </div>
                <div style={{width: "150px", marginLeft: "3px", marginRight: "3px", height: "40px"  }}>
                    <CompFilter token={token} handleComp={setComp} />
                </div>
                <Button style={{ marginLeft: "3px", height: "40px" }} variant="outlined" onClick={() => {
                    // setStartDate();
                    // setActStart();
                    // setActEnd();
                    // setBarcode();
                    // setSCust();
                    // bsearch.current.value = "";
                    window.location.reload(false);

                }}> <RefreshIcon /> </Button>
                <Button style={{ marginLeft: "5px", height: "40px" }} onClick={downloadExcel} variant="outlined">Excel-ээр татах</Button>
            </div>
            }
        </div>

        <div>
        {tranrev ? 
            <TranReverseAdd
            handleCloserev={settranrev}
            openrev={tranrev}
            handleRefreshrev={custDataRefreshrev}
            /> : <></>
        }
        {tranreturn ? 
            <TranReturnAdd
            handleCloserev={settranreturn}
            openrev={tranreturn}
            handleRefreshrev={custDataRefreshrev}
            /> : <></>
        }
        </div>
        
        <div className="tablecont">
              
            {localStorage.getItem('role').includes('117') ? 
            <CustTable name={"Transactions"} items={items} columns={columns} allcont={allcont} handlePage={handlePage} loading={loading} setselect={setselectedData} setSubrow={setSubrow} rowid={(row) => "tran" + row.id} checkboxselect={true} /> : 
            <CustTable name={"Transactions"} items={items} columns={columns} allcont={allcont} handlePage={handlePage} loading={loading} /> }
        </div>
        {addmodal ?
                <TransactionAdd
                    handleClose={setAddModal}
                    token={token}
                    open={addmodal}
                    handleRefresh={custDataRefresh}
                /> : <></>
        }
        
    </div>
}

export default Transactions;